import loginApi from '../../api/login'
import Cookies from 'js-cookie'

const state = {
    staffId: '',
    name: '',
    isAdmin: false,
    rememberMe: false,
    org: {
        name: '',
        orgId: ''
    }
}

const mutations = {
    LOGIN: (state, {
        staffId,
        name,
        isAdmin,
        rememberMe
    }) => {
        state.staffId = staffId;
        state.name = name;
        state.isAdmin = isAdmin;
        state.rememberMe = rememberMe;
    },
    SET_ORG: (state, {
        name,
        orgId
    }) => {
        state.org.name = name
        state.org.orgId = orgId
    },
    LOGOUT: (state) => {
        state.staffId = '';
        state.name = ''
        state.isAdmin = false
        state.org.name = ''
        state.org.orgId = ''
    }
}

const actions = {
    login({
        commit
    }, {
        account,
        password,
        rememberMe
    }) {
        return new Promise((resolve, reject) => loginApi.login(account, password, rememberMe)
            .then(res => {
                commit('LOGIN', {
                    staffId: res.data.staff.staffId,
                    name: res.data.staff.name,
                    isAdmin: res.data.staff.isAdmin,
                    rememberMe: rememberMe
                });
                commit('SET_ORG', {
                    name: res.data.staff.org.name,
                    orgId: res.data.staff.org.orgId
                })
                if (rememberMe) {
                    Cookies.set('auth', res.data.token, {
                        expires: 7
                    })
                } else {
                    Cookies.set('auth', res.data.token);
                }
                resolve();
            }).catch(res => reject(res))
        )
    },
    getUserInfo({
        commit
    }) {
        return new Promise((resolve, reject) => {
            loginApi.getUserInfo()
                .then(res => {
                    if (res.data && res.data.staff) {
                        commit('LOGIN', {
                            staffId: res.data.staff.staffId,
                            name: res.data.staff.name,
                            isAdmin: res.data.staff.isAdmin,
                            rememberMe: res.data.rememberMe
                        })
                        commit('SET_ORG', {
                            name: res.data.staff.org.name,
                            orgId: res.data.staff.org.orgId
                        })
                        resolve()
                    } else {
                        reject()
                    }
                })
        })
    },
    refresh({
        state
    }) {
        loginApi.refresh()
            .then(res => {
                if (state.rememberMe) {
                    Cookies.set('auth', res.data, {
                        expires: 7
                    })
                } else {
                    Cookies.set('auth', res.data)
                }
            })
    },
    logout({
        commit
    }) {
        return new Promise(resolve => {
            loginApi.logout().then(() => {
                Cookies.remove('auth')
                commit('LOGOUT')
                resolve()
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}