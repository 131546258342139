import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Index.vue'
import Login from '../views/Login.vue'
import store from '@/store'
import Cookies from 'js-cookie'
import {
  Dialog
} from 'mand-mobile'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/miniapp/index',
    name: 'WebappIndex',
    component: () => import('../views/webapp/Index'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/miniapp/product',
    name: 'WebappProduct',
    component: () => import('../views/webapp/Product'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/miniapp/tool',
    name: 'WebappTool',
    component: () => import('../views/webapp/Tool'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/miniapp/user-center',
    name: 'WebappUserCenter',
    component: () => import('../views/webapp/UserCenter'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/miniapp/tool/invoice-collection',
    name: 'WebappTool',
    component: () => import('../views/webapp/tool/invoice-collection'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('../views/Guide'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/booking',
    name: 'booking',
    component: () => import('../views/Booking'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/apply/product/:orderId/:sign/:time',
    name: 'Goto',
    component: () => import('../views/Goto'),
    props: true
  },
  {
    path: '/product/list',
    name: 'productList',
    component: () => import('../views/product/list/List'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product/list/apply',
    name: 'productListApply',
    component: () => import('../views/product/list/list-apply'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product/match',
    name: 'match',
    component: () => import('../views/product/match/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product/match/list',
    name: 'matchedList',
    component: () => import('../views/product/match/MatchedList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product/match/result/:id',
    name: 'matchResult',
    component: () => import('../views/product/match/Result.vue'),
    props: true
  },

  {
    path: '/product/applied/list',
    name: 'appliedList',
    component: () => import('../views/product/applied/List')
  },
  {
    path: '/staff/list-normal',
    name: 'normalStaffList',
    component: () => import('../views/staff/list-normal.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/staff/list-frozen',
    name: 'frozenStaffList',
    component: () => import('../views/staff/list-frozen.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/staff/add',
    name: 'addStaff',
    component: () => import('../views/staff/add.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/staff/edit/:staffId',
    name: 'editStaff',
    component: () => import('../views/staff/edit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/change/password',
    name: 'updatePassword',
    component: () => import('../views/change-password.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apply',
    name: 'Apply',
    component: () => import('../views/apply/Index'),
    props: route => ({
      orgId: route.query.orgId,
      staffId: route.query.staffId,
      v: route.query.v
    })
  },
  {
    path: '/apply/status/:orderId',
    name: 'ApplyStatus',
    component: () => import('../views/apply/StatusPage'),
    props: true,
    meta: {
      title: '订单状态'
    }
  },
  {
    path: '/apply/hmd/:orderId',
    name: 'HmdPage',
    component: () => import('../views/apply/HmdPage'),
    props: true
  },
  {
    path: '/apply/withdrawals/:orderId',
    name: 'Withdrawals',
    component: () => import('../views/apply/Withdrawals'),
    props: true
  },
  {
    path: '/apply/:pCode',
    name: 'InputEnterprise',
    component: () => import('../views/apply/InputEnterprise'),
    props: route => ({
      orgId: route.query.orgId,
      staffId: route.query.staffId,
      v: route.query.v,
      pCode: route.params.pCode,
      newOrder: route.query.newOrder === true || route.query.newOrder === 'true'
    })
  },
  {
    path: '/pingan',
    name: 'Pingan',
    component: () => import('../views/apply/hmd/PinganPage')
  },
  {
    path: '/etax/auth/:orderId/:sign',
    name: 'ETaxAuth',
    component: () => import('../views/etax/Auth'),
    props: true
  },
  {
    path: '/etax/auth/simple/:creditCode/:sign',
    name: 'ETaxSimpleAuth',
    component: () => import('../views/etax/SimpleAuth'),
    meta: {
      requiresAuth: false
    },
    props: true
  },
  {
    path: '/etax-auth/:creditCode/:sign',
    name: 'ETaxSimpleAuthV2',
    component: () => import('../views/etax/SimpleAuthV2'),
    meta: {
      requiresAuth: false
    },
    props: true
  },
  {
    path: '/etax-data/crawl/step1',
    name: 'EtaxAuth',
    component: () => import('../views/EtaxAuthStep1'),
    meta: {
      requiresAuth: false
    },
    props: false
  },
  {
    path: '/etax-data/crawl/step2/:creditCode/:sign',
    name: 'EtaxAuth',
    component: () => import('../views/EtaxAuthStep2'),
    meta: {
      requiresAuth: false
    },
    props: true
  },
  {
    path: '/etax-data/crawl/step3/:creditCode',
    name: 'EtaxAuth',
    component: () => import('../views/EtaxAuthStep3'),
    meta: {
      requiresAuth: false
    },
    props: true
  },
  {
    path: '/agent/customer-info/input',
    name: 'ETaxSimpleAuthStep1',
    component: () => import('../views/etax/SimpleAuthStep1'),
    meta: {
      requiresAuth: false,
      title:" ",
    },
    props: true
  },
  {
    path: '/agent/etax-data/auth/:nsrsbh/:nsrmc',
    name: 'ETaxSimpleAuthStep2',
    component: () => import('../views/etax/SimpleAuthStep2'),
    meta: {
      requiresAuth: false,
      title:" ",
    },
    props: true
  },
  {
    path: '/agreement/content/:id',
    name: 'AgreementDetail',
    component: () => import('../views/agreement/detail'),
    props: true
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: {
          y: 50
        }
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '税银助手'
  }
  if (store.state.login.name) {
    if (to.name === 'login') {
      next('/')
    } else {
      adminAuth(to, next)
    }
  } else {
    let token = Cookies.get('auth')
    if (token) {
      store.dispatch('login/getUserInfo')
        .then(() => {
          if (to.name === 'login') {
            next('/')
          } else {
            adminAuth(to, next)
          }
        })
        .catch(() => {
          Cookies.remove('auth')
          if (to.meta && to.meta.requiresAuth) {
            next(`/login?redirect=${to.path}`)
          } else {
            next()
          }
        })
    } else {
      if (to.meta && to.meta.requiresAuth) {
        next(`/login?redirect=${to.path}`)
      } else {
        next()
      }
    }
  }
})

const adminAuth = function (to, next) {
  if (to.meta && to.meta.requiresAdmin && !store.state.login.isAdmin) {
    Dialog.alert({
      title: '权限不足',
      content: '您没相关权限',
      confirmText: '确定',
      onConfirm: () => router.push('/'),
    })
  } else {
    next()
  }
}

export default router