import request from '../util/request'
import qs from 'qs'

export default {
    login(account, password, rememberMe) {
        return request({
            url: '/login',
            method: "POST",
            data: qs.stringify({username: account, password: password, rememberMe: rememberMe})
        })
    },
    refresh() {
        return request({
            url: '/refresh',
            method: 'GET'
        })
    },
    logout() {
        return request({
            url: '/logout',
            method: 'GET'
        })
    },
    getUserInfo() {
        return request({
            url: '/getUserInfo',
            method: 'GET'
        })
    },
    updatePassword(data) {
        return request({
            url: '/updatePassword',
            method: 'POST',
            data
        })
    }
}