<template>
  <div class="menu-item" @click="linkTo">
        <div class="menu-icon">
            <slot name="icon"></slot>
        </div>
        <div class="menu-title">{{title}}</div>
    </div>
</template>

<script>
export default {
    name: 'block-menu',
    props: {
        title: {
            type: String
        },
        to: {
            type: String
        }
    },
    methods: {
        linkTo() {
            if (this.to) {
                this.$router.push(this.to);
            }
        }
    }
}
</script>

<style scoped>
	.menu-item {
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    flex-grow: 1;
  }
  .menu-icon {
    width: 100px;
    height: 100px;
    /*background-color: #e3e3e3;*/
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-title {
    text-align: center;
    margin-top: 10px;
  }
</style>