import Vue from 'vue'
import './util/Date'
import App from './App.vue'
import router from './router'
import store from './store'
import PageLayout from '@/components/PageLayout'
import JzsyHeader from '@/components/JzsyHeader'
import JzsyTabbar from '@/components/JzsyTabbar'
import 'normalize.css';
import './plugins/element.js'
import './assets/style.css'
import './plugins/mandMobile'
import './plugins/vee-validate'
import './plugins/vueSwiper'
import './plugins/vant'
import '@/svg';
import SvgIcon from '@/components/SvgIcon';

Vue.component('SvgIcon', SvgIcon);

Vue.config.productionTip = false
Vue.component(PageLayout.name, PageLayout)
Vue.component(JzsyHeader.name, JzsyHeader)
Vue.component(JzsyTabbar.name, JzsyTabbar)

Vue.directive('title', (el, binding) => {
  document.title = binding.value
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
