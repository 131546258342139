import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize
} from 'vee-validate'
import {
  required,
  confirmed
} from 'vee-validate/dist/rules'

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', required)
extend('confirmed', confirmed)
extend("phone", {
  getMessage: field => `${field}格式错误`,
  validate: value => /^1[3456789]\d{9}$/.test(value)
});
extend("creditCode", {
  getMessage: () => `请输入正确的信用代码`,
  validate: value => value.length === 18 && value.startsWith('9')
});
extend("enterpriseName", {
  getMessage: () => `请输入企业名称`,
  validate: value => value.length >= 5
});

localize("zh_CN", {
  name: 'zh_CN',
  messages: {
    _default: (field) => `${field}无效`,
    after: (field, {
      target
    }) => `${field}必须在${target}之后`,
    alpha_dash: (field) => `${field}能够包含字母数字字符、破折号和下划线`,
    alpha_num: (field) => `${field}只能包含字母数字字符`,
    alpha_spaces: (field) => `${field}只能包含字母字符和空格`,
    alpha: (field) => `${field}只能包含字母字符`,
    before: (field, {
      target
    }) => `${field}必须在${target}之前`,
    between: (field, {
      min,
      max
    }) => `${field}必须在${min}与${max}之间`,
    confirmed: (field, {
        target
      }) =>
      `${field}和${target}不一致`,
    credit_card: (field) => `${field}格式错误`,
    date_between: (field, {
      min,
      max
    }) => `${field}必须在${min}和${max}之间`,
    date_format: (field, {
      format
    }) => `${field}必须符合${format}格式`,
    decimal: (field, {
        decimals = '*'
      } = {}) =>
      `${field}必须是数字，且能够保留${
              decimals === '*' ? '' : decimals}位小数`,
    digits: (field, {
        length
      }) =>
      `${field}必须是数字，且精确到${length}位数`,
    dimensions: (field, {
        width,
        height
      }) =>
      `${field}必须在${width}像素与${height}像素之间`,
    email: (field) => `${field}不是一个有效的邮箱`,
    ext: (field) => `${field}不是一个有效的文件`,
    image: (field) => `${field}不是一张有效的图片`,
    included: (field) => `${field}不是一个有效值`,
    integer: (field) => `${field}必须是整数`,
    ip: (field) => `${field}不是一个有效的地址`,
    length: (field, {
      length,
      max
    }) => {
      if (max) {
        return `${field}长度必须在${length}到${max}之间`
      }
      return `${field}长度必须为${length}`
    },
    max: (field, {
      length
    }) => `${field}不能超过${length}个字符`,
    max_value: (field, {
      max
    }) => `${field}必须小于或等于${max}`,
    mimes: (field) => `${field}不是一个有效的文件类型`,
    min: (field, {
      length
    }) => `${field}必须至少有${length}个字符`,
    min_value: (field, {
      min
    }) => `${field}必须大于或等于${min}`,
    excluded: (field) => `${field}不是一个有效值`,
    numeric: (field) => `${field}只能包含数字字符`,
    regex: (field) => `${field}格式无效`,
    required: (field) => `${field}不能为空`,
    url: (field) => field + '不是一个有效的url',
    phone: (field) => `${field}格式错误`,
    creditCode: field => `${field}格式错误`,
    enterpriseName: field => `${field}不能为空`

  }
});