<template>
  <div>
    <div class="wrap" v-bind:style="{ background: themeColor }">
      <div class="left-nav-btn">
        <md-icon class="nav-btn-item" name="arrow-left" v-if="showBack" @click="back"></md-icon>
        <md-icon class="nav-btn-item" name="home" v-if="showHome" @click="home"></md-icon>
        <slot name="left"></slot>
      </div>
      <div class="title">{{title}}</div>
      <div class="right-nav-btn">
        <slot name="right"></slot>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
    name: 'JzsyHeader',
    props: {
      showBack: {
        type: Boolean,
        default: true
      },
      showHome: {
        type: Boolean,
        default: true
      },
      title : {
        type: String,
        default: "橘子税银"
      },
      themeColor : {
        type: String,
        default: "#7147B5"
      },
      
    },
    methods: {
      back() {
        this.$router.go(-1)
      },
      home() {
        this.$router.push({name: 'home'})
      }
    }
}
</script>

<style scoped>
  .placeholder, .wrap {
    height: 92px;
  }
  .wrap {
    width: 100%;
    background: #7147B5;
    text-align: center;
    font-size: 36px;
    line-height: 92px;
    color: #ffffff;
    position: fixed;
    z-index: 999;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
  }
  .title {
    font-size: 32px;
  }
  .left-nav-btn {
    position: absolute;
    top: auto;
    left: auto;
  }
  .nav-btn-item {
    padding: 0 10px;
  }
  .left-nav-btn .nav-btn-item {
    margin-left: 15px;
  }
  .right-nav-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  .right-nav-btn .nav-btn-item {
    margin-right: 15px;
  }
</style>