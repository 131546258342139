<template>
  <page-layout page-name="登录" :show-home="false">
    <md-field class="main">
      <ValidationObserver ref="form">
        <ValidationProvider rules="required" name="手机号" v-slot="{ errors }">
          <md-input-item
            class="input-item"
            type="text"
            placeholder="请输入手机号"
            v-model="account"
            is-title-latent
            :maxlength="11"
            is-highlight
            title="手机号"
            :error="errors[0]"
            :disabled="loading"
          >
            <md-icon name="user" slot="left" color="#9579E5"></md-icon>
          </md-input-item>
        </ValidationProvider>
        <ValidationProvider rules="required" name="密码" v-slot="{ errors }">
          <md-input-item
            class="input-item"
            type="password"
            placeholder="请输入密码"
            v-model="password"
            is-title-latent
            :maxlength="16"
            is-highlight
            title="密码"
            :error="errors[0]"
            :disabled="loading"
          >
            <md-icon name="security" slot="left" color="#9579E5"></md-icon>
          </md-input-item>
        </ValidationProvider>
        <div class="remember-me">
          <md-switch
            v-model="rememberMe"
            class="remember-me"
            :disabled="loading"
          />
          <label>7天内记住登录</label>
        </div>
        <md-button
          class="login-btn"
          type="primary"
          size="small"
          :plain="true"
          :loading="loading"
          @click="loginHandle"
          >登录</md-button
        >
      </ValidationObserver>
<!--      <md-button type="link" class="guide-btn" @click="$router.push('/guide')">操作指引</md-button>-->
<!--      <md-button type="link" class="guide-btn" @click="$router.push('/booking')">开通账号</md-button>-->
    </md-field>
  </page-layout>
</template>

<script>
import { mapActions } from "vuex";
import { Switch } from "mand-mobile";

export default {
  name: "Login",
  components: { [Switch.name]: Switch },
  data() {
    return {
      account: "",
      password: "",
      rememberMe: true,
      redirect: "",
      loading: false,
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("login", ["login"]),
    loginHandle: function () {
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.loading = true;
          this.login({
            account: this.account,
            password: this.password,
            rememberMe: this.rememberMe,
          })
            .then(() => {
              if (this.redirect) {
                this.$router.push({ path: this.redirect });
              } else {
                this.$router.push({ name: "home" });
              }
            })
            .finally(() => (this.loading = false));
        }
      });
    },
  },
};
</script>

<style scoped>
.main {
  width: 80%;
  margin: 0 auto;
}
.login-btn {
  margin-top: 30px;
}
.input-item input .login-btn .input-item {
  font-size: 24px !important;
}
.remember-me {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-top: 50px;
}
.remember-me label {
  font-size: 24px;
  margin: 0 20px;
  color: #c5cad5;
}
.guide-btn {
  margin-top: 60px;
  float: right;
}
</style>
