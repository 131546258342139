<template>
  <div class="jzsy-tabbar">
    <van-tabbar route placeholder>
      <van-tabbar-item icon="home-o" to="/miniapp/index">首页</van-tabbar-item>
      <van-tabbar-item icon="bag-o" to="/miniapp/product">产品</van-tabbar-item>
      <van-tabbar-item icon="setting-o" to="/miniapp/tool">工具</van-tabbar-item>
      <van-tabbar-item icon="user-o" to="/miniapp/user-center">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant';

export default {
  name: "JzsyTabbar",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      
    };
  },
  created() {
  },
  methods: {
    
  },
};
</script>

<style>
.jzsy-tabbar .van-tabbar {
  height: 1rem;
}
.jzsy-tabbar .van-tabbar-item__icon {
  margin-bottom: 0.08rem;
  font-size: 0.4rem;
}
</style>