import request from '../util/request'
import qs from 'qs'

export default {
    search(keyword) {
        return request({
            url: '/match/search',
            method: 'POST',
            data: qs.stringify({keyword: keyword})
        })
    },
    matching(formData) {
        return request({
            url: '/match/matching',
            method: 'POST',
            data: formData
        })
    },
    query(id) {
        return request({
            url: '/match/query',
            method: 'POST',
            data: qs.stringify({id: id})
        })
    },
    matchAmountOfStaff() {
        return request({
            url: '/match/statistics/amountForMonth',
            method: 'GET'
        })
    }
    ,
    matchAmountOfOrg() {
        return request({
            url: '/match/statistics/amountOfOrgForMonth',
            method: 'GET'
        })
    },
    matchedPerday() {
        return request({
            url: '/match/statistics/matchedPerday',
            method: 'GET'
        })
    },
    matchedPerdayOfOrg() {
        return request({
            url: '/match/statistics/matchedPerdayOfOrg',
            method: 'GET'
        })
    },
    matchedRecordsOfStaff(data) {
        return request({
            url: '/match/statistics/matchedRecordsOfStaff',
            method: 'POST',
            data
        })
    },
    matchedRecordsOfOrg(data) {
        return request({
            url: '/match/statistics/matchedRecordsOfOrg',
            method: 'POST',
            data
        })
    }
}