<template>
  <div class="home">
    <jzsy-header :show-home="false" :show-back="false" title="橘子快贷">
      <div slot="right" class="logout-btn" @click="logoutHandle">
        <svg-icon name="log-out" size="0.38rem"></svg-icon>
      </div>
    </jzsy-header>
    <div class="main-wrapper">
      <div class="identity-card">
        <div class="user-info">
          <div class="org-name">{{ org.name }}</div>
          <div>{{ name }}</div>
        </div>
      </div>
      <div v-if="!distributeOrg" class="content-box">
        <div class="arrow"></div>
        <div class="content">
          <div class="square">
            <div class="square-title">本月匹配</div>
            <div style="color: #3f2865">
              <label
                class="matched-count"
                v-show="!state.loadingMatchCount"
                @click="toMatchedList"
                >{{ matchAmount }}</label
              >
              <md-icon
                name="spinner"
                size="lg"
                style="-webkit-filter: invert(1)"
                v-show="state.loadingMatchCount"
              />
              <label>户</label>
            </div>
          </div>
          <div class="square">
            <md-button type="primary" plain inline @click="toMatch"
              >立即匹配</md-button
            >
          </div>
        </div>
      </div>
      <div class="grid-box">
        <van-grid column-num="4">
          <van-grid-item
            v-for="item in menuList"
            :key="item.id"
            :text="item.name"
            :to="item.url"
          >
            <template slot="icon">
              <svg-icon slot="icon" :name="item.icon" size="1rem" />
            </template>
          </van-grid-item>
          <van-grid-item
            key="0"
            text="产品推广"
            @click="toApplyList"
            v-if="state.productPromote"
          >
            <template slot="icon">
              <svg-icon slot="icon" name="产品推广" size="1rem" />
            </template>
          </van-grid-item>

          <!-- <van-grid-item
            v-if="!distributeOrg"
            text="产品匹配"
            to="/product/match"
          >
            <template slot="icon">
              <svg-icon slot="icon" name="产品匹配" size="1rem" />
            </template>
          </van-grid-item>
          <van-grid-item
            v-if="!distributeOrg"
            text="匹配记录"
            to="/product/match/list"
          >
            <template slot="icon">
              <svg-icon slot="icon" name="匹配记录" size="1rem" />
            </template>
          </van-grid-item>
          <van-grid-item
            v-if="!distributeOrg"
            text="贷款申请"
            @click="toApplyList"
          >
            <template slot="icon">
              <svg-icon slot="icon" name="贷款申请" size="1rem" />
            </template>
          </van-grid-item>

          <van-grid-item text="申请记录" to="/product/applied/list">
            <template slot="icon">
              <svg-icon slot="icon" name="申请记录" size="1rem" />
            </template>
          </van-grid-item>
          <van-grid-item text="产品列表" to="/product/list">
            <template slot="icon">
              <svg-icon slot="icon" name="产品列表" size="1rem" />
            </template>
          </van-grid-item>
          <van-grid-item
            v-if="!distributeOrg && isAdmin"
            text="人员管理"
            to="/staff/list-normal"
          >
            <template slot="icon">
              <svg-icon slot="icon" name="人员管理" size="1rem" />
            </template>
          </van-grid-item>
          <van-grid-item text="修改密码" to="/change/password">
            <template slot="icon">
              <svg-icon slot="icon" name="修改密码" size="1rem" />
            </template>
          </van-grid-item>
          <van-grid-item v-if="!distributeOrg" text="操作指引" to="/guide">
            <template slot="icon">
              <svg-icon slot="icon" name="操作指南" size="1rem" />
            </template>
          </van-grid-item> -->
        </van-grid>
      </div>
      <van-popup
        v-model="popup.show"
        :closeable="false"
        :close-on-click-overlay="true"
        position="bottom"
        round
        class="popup-message"
      >
        {{ popup.message }}
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Grid, GridItem, Skeleton, Popup } from "vant";
import { Chart, Tag } from "mand-mobile";
import BlockMenu from "@/components/BlockMenu.vue";
import ProductMatchApi from "@/api/productMatch";
import MenuApi from "@/api/menu";
import ApplyApi from "@/api/apply";
import { mapState } from "vuex";

export default {
  name: "home",
  components: {
    [Chart.name]: Chart,
    [Tag.name]: Tag,
    [BlockMenu.name]: BlockMenu,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Skeleton.name]: Skeleton,
    [Popup.name]: Popup,
  },
  data() {
    return {
      matchAmount: 0,
      code: "",
      state: {
        loadingMatchCount: true,
        loadingMenu: true,
        productPromote: false,
      },
      popup: {
        show: false,
        message: "",
      },
      menuList: [
        { id: 1, name: "模块名称", icon: "空白", url: "" },
        { id: 2, name: "模块名称", icon: "空白", url: "" },
        { id: 3, name: "模块名称", icon: "空白", url: "" },
        { id: 4, name: "模块名称", icon: "空白", url: "" },
        { id: 5, name: "模块名称", icon: "空白", url: "" },
        { id: 6, name: "模块名称", icon: "空白", url: "" },
        { id: 7, name: "模块名称", icon: "空白", url: "" },
        { id: 8, name: "模块名称", icon: "空白", url: "" },
      ],
    };
  },
  computed: {
    ...mapState("login", ["staffId", "name", "org", "isAdmin"]),
    distributeOrg() {
      return (
        this.org.orgId === 38 || this.org.orgId === 82 || this.org.orgId === 89
      );
    },
  },
  methods: {
    showPopup(message) {
      this.popup.show = true;
      this.popup.message = message;
    },
    toApplyList() {
      this.$router.push(
        `/apply?orgId=${this.org.orgId}&staffId=${this.staffId}&v=${this.code}`
      );
    },
    toMatch() {
      this.$router.push("/product/match");
    },
    toMatchedList() {
      this.$router.push("/product/match/list");
    },
    logoutHandle() {
      this.$dialog.confirm({
        title: "温馨提示",
        content: "您确定要退出登录吗？",
        confirmText: "确定",
        onConfirm: () => {
          this.$store.dispatch("login/logout").then(() => {
            this.$router.push("/login");
          });
        },
      });
    },
    loadMatchAmountData() {
      if (this.isAdmin) {
        ProductMatchApi.matchAmountOfOrg().then((res) => {
          this.matchAmount = res.data;
          this.state.loadingMatchCount = false;
        });
      } else {
        ProductMatchApi.matchAmountOfStaff().then((res) => {
          this.matchAmount = res.data;
          this.state.loadingMatchCount = false;
        });
      }
    },
    loadMenuData() {
      MenuApi.allMenus().then((res) => {
        this.menuList = res.data;
        this.state.loadingMenu = false;
        this.validateLink();
      });
    },
    validateLink() {
      ApplyApi.validateLink({
        orgId: this.org.orgId,
        staffId: this.staffId,
        v: "jzsy",
      })
        .then((res) => {
          if (res.data) {
            this.code = res.data;
            this.state.productPromote = true;
          } else {
            this.state.productPromote = false;
          }
        })
        .catch((res) => {
          this.state.productPromote = false;
          this.showPopup(res.msg ? res.msg : "系统异常，请稍后再试！");
        });
    },
  },
  mounted() {
    this.loadMenuData();
    this.loadMatchAmountData();
  },
};
</script>

<style scoped>
.home {
  background: #d7d7d7;
  font-size: 24px;
}
.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}
.identity-card {
  width: 95%;
  margin: 20px 20px 0;
  display: flex;
  border-radius: 8px 8px 0 0;
  background: #3f2865;
  position: relative;
  box-shadow: 8px -5px 5px 3px rgba(0, 0, 0, 0.1);
}
.user-info {
  height: 120px;
  color: #ffe07c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  line-height: 48px;
  flex-grow: 1;
}
.user-info .org-name {
  font-size: 36px;
}
.logout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
  margin-right: 30px;
}
.content-box {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.arrow {
  border: 20px solid;
  border-color: #3f2865 transparent transparent transparent;
  width: 0;
  height: 0;
}
.content {
  display: flex;
  width: 100%;
}
.square {
  flex-grow: 1;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.matched-count {
  font-size: 64px;
  margin-right: 20px;
}
.grid-box {
  background: #fff;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.popup-message {
  padding: 30px 0;
  color: #ffffff;
  background-color: #ff976a;
  font-size: 36px;
  text-align: center;
}
</style>