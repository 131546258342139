import Vue from 'vue'
import {
    ActionSheet,
    Dialog,
    Toast,
    InputItem,
    Field,
    Icon,
    Button,
    Check,
    CheckBox,
    CheckGroup,
    CheckList,
    Selector,
    Popup,
    PopupTitleBar,
    Transition
}  from 'mand-mobile'

// Register global components
Vue.component(InputItem.name, InputItem)
Vue.component(Field.name, Field)
Vue.component(Icon.name, Icon)
Vue.component(Button.name, Button)
Vue.component(Check.name, Check)
Vue.component(CheckBox.name, CheckBox)
Vue.component(CheckGroup.name, CheckGroup)
Vue.component(CheckList.name, CheckList)
Vue.component(Selector.name, Selector)
Vue.component(Popup.name, Popup)
Vue.component(PopupTitleBar.name, PopupTitleBar)
Vue.component(Transition.name, Transition)

// Mount to prototype
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog
Vue.prototype.$actionsheet = ActionSheet