<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }
  .content-wrapper {
    font-size: 24px;
  }
  .search-input input {
    font-size: 32px;
    padding: 15px;
  }
  .apply-btn .van-button--large {
    height: 72px !important;
  }
  .apply-btn span {
    font-size: 32px;
  }
</style>
