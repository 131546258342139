import axios from 'axios'
import { Dialog, Toast } from 'vant'
import Cookies from 'js-cookie'
import router from '@/router'
import store from '../store'

const service = axios.create({
  baseURL: '/web',
  timeout: 50000
});

service.interceptors.request.use(
  config => {
    config.headers = {
      ...config.headers,
      'auth': Cookies.get('auth')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== '200') {
      let message = res.msg || '未知错误！';
      if (res.code === '401') {
        Cookies.remove('auth')
        store.commit('login/LOGOUT')
        Dialog.confirm({
          title: '登录提示',
          message: '您尚未登录，是否登录？',
          showCancelButton: true,
          cancelButtonText: '取消',
          confirmButtonText: '去登录',
        }).then(() => {
          router.push('/login')
        })
      } else {
        Dialog.alert({
          title: '',
          message: message
        }).then(() => {
          return Promise.reject(res)
        })
      }
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    Toast.failed(error.message);
    return Promise.reject(error)
  }
)

export default service
