import request from '../util/request'
import qs from 'qs'

export default {
  productInfo(data) {
    return request({
      url: '/apply/productInfo',
      method: 'post',
      data
    })
  },
  queryProduct(data) {
    return request({
      url: '/apply/queryProduct',
      method: 'post',
      data
    })
  },
  applyLink(data) {
    return request({
      url: '/apply/createLink',
      method: 'POST',
      data
    })
  },
  validateOrder(orderId) {
    return request({
      url: '/apply/validateOrder',
      method: 'post',
      data: qs.stringify({
        orderId
      })
    })
  },
  withdrawals(orderId, code) {
    return request({
      url: '/apply/withdrawals',
      method: 'post',
      data: qs.stringify({
        orderId,
        code
      })
    })
  },
  validateLink(data) {
    return request({
      url: '/apply/validate/link',
      method: 'POST',
      data
    })
  },
  applyProduct(orderId, sign, time) {
    return request({
      url: '/apply/product/'+orderId+'/'+sign+'/'+time,
      method: 'GET'
    })
  },
}